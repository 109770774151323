import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenCodeExchange, TokenResponse, User } from '@parashift/shared/models';
import { UserType } from '@parashift/shared/types';
import { ServiceDecorator } from './decorators';
import { BaseApiService } from './base';
import { QueryParamsService } from '../query-params.service';
import { Endpoint } from '../env.service';

@Injectable({
  providedIn: 'root'
})
@ServiceDecorator({
  model: () => User,
  endpointUrl: UserType
})
export class UserService extends BaseApiService<User> {
  className = UserService;

  constructor(
    http: HttpClient,
    queryParamsService: QueryParamsService
  ) {
    super(http, queryParamsService);
    this.baseUrl = Endpoint.id;
    this.apiVersion = this.environment.endpoints.version
  }

  getToken(tokenCodeExchange: TokenCodeExchange): Observable<TokenResponse> {
    const url = this.environment.endpoints.id  + '/users/auth/token';
    return this.http.post<TokenResponse>(url, undefined, {
      params: { 'user[id]': tokenCodeExchange.id, 'user[code_verifier]': tokenCodeExchange.code_verifier }
    });
  }

  resendConfirmationLink(user_id: string) {
    const url = this.buildUrl(user_id);
    return this.http.post(url + '/resend_confirmation', '{}');
  }
}
